import React from 'react';
import {useCreateWebhookMutation, useListPCCWebhooksQuery} from "../../../store/api/webhook";
import {Box, Button, Card, CardContent, Chip, Divider, Stack, Typography} from "@mui/material";
import {PCCWebHook} from "../../../models/webhook";
import StateLoadingWrapper from "../../common/StateLoadingWrapper/StateLoadingWrapper";
import HBox from "../../common/box/HBox/HBox";
import {handleErrorOrProceed} from "../../../common/formik";
import {toast} from "react-toastify";


interface HookListItemProps {
    hook: PCCWebHook
}

function HookListItem(props: HookListItemProps): React.ReactElement {
    const {hook} = props;
    return <Card style={{width: '100%'}}>

        <CardContent>
            <Typography variant={'caption'}>{hook.webhookSubscriptionId}</Typography>
            <Typography variant={'h5'}>{hook.applicationName}</Typography>
            <Divider>DETAILS</Divider>
            <Typography variant={'body2'}>URL: {hook.endUrl}</Typography>
            <Typography variant={'body2'}>Username: {hook.username}</Typography>
            <Box mt={1} mb={2}>
                <Chip size={'small'} label={hook.status} />
            </Box>
            <Divider>EVENTS</Divider>
            <Box mt={1}>
                <Stack spacing={1} direction={'row'}>
                    {
                        hook.eventGroupList.map((event, pos) => {
                            return <Chip size={'small'} key={pos} label={event} />
                        })
                    }
                </Stack>
            </Box>
        </CardContent>
    </Card>
}


function AdminPageHooksList(): React.ReactElement {

    const hooksState = useListPCCWebhooksQuery({});
    const [createWebhook, {isLoading: isCreating}] = useCreateWebhookMutation();
    const hooks = hooksState.data?.data;

    async function onCreateClicked(e: React.MouseEvent){
        e.stopPropagation();
        e.preventDefault();

        const result = await createWebhook({})

        if (handleErrorOrProceed(result)) {
            toast.success('Webhook created successfully');
        }
    }

    return <Stack spacing={1} alignItems={"flex-start"}>
        <Typography variant={"h4"}>🎣 Webhooks</Typography>
        <StateLoadingWrapper state={hooksState}>
            <Stack spacing={1}>
                {
                    hooks && hooks.length && hooks.map((hook) => {
                        return <HookListItem key={hook.webhookSubscriptionId} hook={hook}/>
                    })
                }
            </Stack>
        </StateLoadingWrapper>

        <HBox mt={2}>
            <Stack direction={'row'} spacing={1}>
                <Button onClick={onCreateClicked} disabled={isCreating}>
                    Add Webhook
                </Button>
            </Stack>
        </HBox>
    </Stack>
}

export default AdminPageHooksList