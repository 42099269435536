import React from 'react';
import {Alert, Box, Grid} from "@mui/material";
import AdminPageHooksList from "../AdminPageHooksList/AdminPageHooksList";
import {useIsSuperUser} from "../../../common/isSuperuser";

function AdminPage(): React.ReactElement {

    const isSuperUser = useIsSuperUser();

    if (!isSuperUser) {
        return <Box m={2}>
            <Alert severity={'error'}>
                You are not authorized to view this page.
            </Alert>
        </Box>
    }

    return (
        <Grid container>
            <Grid item xs={12} md={6} lg={4}>
                <AdminPageHooksList />
            </Grid>
        </Grid>
    );
}

export default AdminPage