import React from 'react';
import {useListFacilitiesFromPCCQuery} from "../../../store/api/pccFacility";
import StateLoadingWrapper from "../../common/StateLoadingWrapper/StateLoadingWrapper";
import {
    Box,
    Grid,
} from "@mui/material";
import AppErrorAlert from "../AppErrorAlert/AppErrorAlert";
import PCCFacilityCard from "../PCCFacilityCard/PCCFacilityCard";


function PCCFacilitiesList(): React.ReactElement {


    const pccFacilitiesState = useListFacilitiesFromPCCQuery({});

    const facilities = pccFacilitiesState.data?.data;
    const error = pccFacilitiesState.error;
    return <>
        {!!error && <AppErrorAlert error={error} />}
        <StateLoadingWrapper state={pccFacilitiesState}>
            <Grid container>
                {!!facilities && facilities.map((facility) => {
                    return (
                        <Grid item xs={6} md={4} lg={2}  key={facility.facId}>
                            <Box m={1}>
                                <PCCFacilityCard facility={facility} />
                            </Box>
                        </Grid>
                    )
                    })
                }
            </Grid>

        </StateLoadingWrapper>
    </>;
}

export default PCCFacilitiesList