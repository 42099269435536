import {buildRTKAPI} from "../builder";
import {baseApi} from "./base";
import {Medication, MedicationListParams} from "../../models/medication";


const api = baseApi.injectEndpoints({
    endpoints: (builder) => {
        const api = buildRTKAPI<
            Medication,
            MedicationListParams
        >(
            builder,
            "Medication",
            "/medications",
            {
                // Options
            }
        );

        return {
            listMedications: api.getList,
        };
    },
});

export const {
    useListMedicationsQuery,
} = api;
