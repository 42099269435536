import React from 'react';
import {NavLink, NavLinkProps} from "react-router-dom";
import {theme} from "../../../theme/theme";

interface TLNavLinkProps extends NavLinkProps{

}

function TLNavLink(argProps: TLNavLinkProps): React.ReactElement {
    const {style, ...props} = argProps;

    const defaultStyle = {
        textDecoration: "none",
        color: theme.palette.primary.main,
        fontWeight: "bold",
    }



    return (
        <NavLink style={{...defaultStyle, ...style}} {...props} />
    );
}

export default TLNavLink