import {baseApi} from "./base";
import {PCCFacility} from "../../models/pccFacility";
import {PCCListRequestParams, PCCListResponse} from "../../models/pcc";

const api = baseApi.injectEndpoints({
    endpoints: (builder) => {

        return {

            listFacilitiesFromPCC: builder.query<PCCListResponse<PCCFacility>, PCCListRequestParams>({
                query: (params) => ({
                    url: `/facilities/pcc_facilities/`,
                    params,
                }),

                providesTags: ['PCCFacility'],
            }),

            onboardPCCFacility: builder.mutation<PCCFacility, PCCFacility>({
                query: (data) => ({
                    url: `/facilities/onboard_pcc_facility/`,
                    method: 'POST',
                    body: data,
                }),
                invalidatesTags: [
                    'Facility'
                ]
            }),
        };
    },
});

export const {
    useListFacilitiesFromPCCQuery,
    useOnboardPCCFacilityMutation,
} = api;
