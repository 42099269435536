import { createTheme } from '@mui/material'

// https://mui.com/material-ui/getting-started/faq/
// If you feel like customizing UI field, do it here
// Otherwise the app uses base components from material
// and any changes here should apply everywhere
export const theme = createTheme({

    palette:{
      primary: {
          main: "#FA7268",
      },
      secondary: {
            main: "#51736c",
      },

        text: {
            primary: '#2e2a28',
        }
    },
    typography:{
        fontFamily: 'Roboto',

        h5: {
            fontFamily: 'Poppins',
            color: '#51736c',
            fontWeight: 800,
        },
        h6: {
            color: 'rgb(44, 37, 36)'
        }
    },

    components: {

        MuiButtonBase: {
            defaultProps: {
                disableRipple: true, // No more ripple, on the whole application 💣!
                color: '#2e2a28',
            },
        },
    },
})
