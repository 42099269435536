import {Box, Card, CardContent, Chip, Stack, Typography} from '@mui/material';
import React from 'react';
import {usePaginationContext, useRouteBasedPagination} from "../../../common/pagination/pagination";
import {useListMedicationsQuery} from "../../../store/api/medication";
import HBox from '../../common/box/HBox/HBox';
import PaginationChevrons from "../../common/PaginationChevrons/PaginationChevrons";
import StateLoadingWrapper from "../../common/StateLoadingWrapper/StateLoadingWrapper";

interface PatientMedicationsListProps {
    patientId: string;
}

function PatientMedicationsList(argProps: PatientMedicationsListProps): React.ReactElement {
    const {patientId} = argProps;

    const pagination = useRouteBasedPagination(5, 'medsPage');

    const medsState = useListMedicationsQuery({
        ...pagination.paginationFilters,
        patient: patientId
    });

    const meds = medsState?.data?.results;
    const paginationContext = usePaginationContext({
        ...pagination,
        data: medsState.data,
    })
    return (
        <Stack spacing={1}>
            <Typography variant={'h5'}>💉 Medications</Typography>

            <StateLoadingWrapper state={medsState}>
                <Stack spacing={1} alignItems="flex-start">
                    {
                        !!meds && meds.map(med => {
                            const isActive = (med.status || "").toLowerCase() === 'active';
                            return <Card key={med.id} style={{width: '100%'}}>
                                <CardContent>
                                    <Typography variant={"caption"}>{med.generic}</Typography>
                                    <Typography variant={"h6"}>{med.description}</Typography>
                                    <Box mt={1}>
                                        <Typography variant={'body2'}>
                                            <b>Directions:</b> {med.directions}
                                        </Typography>
                                    </Box>
                                    <HBox mt={2}>
                                        <Chip label={med.status} color={isActive ? 'primary' : undefined} />
                                    </HBox>
                                </CardContent>
                            </Card>
                        })
                    }
                    {meds && !meds.length && <HBox c mt={2}>
                        <Typography variant={'caption'}>No medications found for this patient. 👀</Typography>
                        </HBox>
                    }
                </Stack>
            </StateLoadingWrapper>


            <Box m={1} pl={2}>
                <PaginationChevrons showPage isFetching={medsState.isFetching} context={paginationContext}/>
            </Box>

        </Stack>
    );
}

export default PatientMedicationsList