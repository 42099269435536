import {formatDistance, isPast} from "date-fns";

export function getRelativeDateTimeFromString(date: string, baseDate = new Date()){

    const parsedDate = new Date(date);
    const relativeDate = formatDistance(parsedDate, baseDate);
    if (isPast(parsedDate)){
        return `${relativeDate} ago`;
    } else {
        return `in ${relativeDate}`;
    }

}


export function getFormattedDate(date: string){
    const parsedDate = new Date(date);
    return parsedDate.toLocaleDateString();
}