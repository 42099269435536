import React from 'react'
import CBox, { CBoxProps } from '../box/CBox/CBox'

interface StateLoadingWrapperProps extends CBoxProps {
    state: {
        data?: unknown
        isLoading: boolean
    }
}

function StateLoadingWrapper(
    argProps: StateLoadingWrapperProps
): React.ReactElement {
    const {
        state: { data, isLoading },
        children,
        ...props
    } = argProps
    return !data && isLoading ?  <CBox {...props}>Loading...</CBox> : <>{children}</>;
}

export default StateLoadingWrapper
