import React from 'react'
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Button,
    ButtonBase,
    Card, CardContent,
    Stack,
    Typography
} from '@mui/material'
import {LoginForm} from '../../../models/session'
import {useFormik} from 'formik'
import FormikTextField from '../../common/form/FormikTextField/FormikTextField'
import {useLoginMutation} from '../../../store/api/session'
import {handleErrorOrProceed} from '../../../common/formik'
import TLForm from '../../common/form/TLForm/TLForm'
import HBox from '../../common/box/HBox/HBox'

function getPointClickCareLoginUrl() {

    const clientId = process.env['REACT_APP_PCC_CLIENT_ID'];
    const pccAuthURL = process.env['REACT_APP_PCC_AUTH_URL'];
    const apiURL = process.env['REACT_APP_API_ROOT'];

    const args = {
        client_id: clientId,
        response_type: 'code',
        state: 'dev',
        scope: 'openid',
        redirect_uri: apiURL + "/users/auth/pcc_auth/"
    }

    const argsString = Object.entries(args).map(([key, value]) => {
        if (value) {
            return encodeURIComponent(key) + '=' + encodeURIComponent(value);
        } else {
            return encodeURIComponent(key)
        }
    }).join('&');

    return pccAuthURL + '?' + argsString;
}

export function LoginPage(): React.ReactElement {
    const [performLogin, {isLoading}] = useLoginMutation()
    const [loggedIn, setLoggedIn] = React.useState(false)
    const onFormikSubmit = async (values: LoginForm) => {
        const response = await performLogin(values)

        if (handleErrorOrProceed(response, formik)) {
            setLoggedIn(true)
        }
    }

    const formik = useFormik<LoginForm>({
        initialValues: {
            username: '',
            password: '',
        },
        onSubmit: onFormikSubmit,
    })

    if (loggedIn) {
        return (
            <Card style={{minWidth: 400, margin: 100}}>
                <HBox m={3}>
                    <h1>
                        Login successful, if you are not redirected, please
                        refresh the page
                    </h1>
                </HBox>
            </Card>
        )
    }

    function onLoginWithPCCClicked(e: React.MouseEvent) {
        e.stopPropagation();
        e.preventDefault();
        const url = getPointClickCareLoginUrl();
        window.open(url, '_self');
    }

    return (
        <HBox c mt={10}>
            <Stack spacing={4}>
                <Card style={{minWidth: 400}}>
                    <CardContent>
                        <Typography variant={'h6'} style={{textTransform: 'uppercase'}}>Sign In</Typography>
                        <br/>

                        <HBox c>
                            <ButtonBase
                                onClick={onLoginWithPCCClicked}
                                style={{
                                    color: 'rgb(103, 103, 103)',
                                    border: 'solid 1px',
                                    borderRadius: '4px',
                                    borderColor: '#cccccc70',
                                    padding: '10px 10px',
                                    fontSize: 16,
                                    boxShadow: '#80808030 1px 2px 4px 0px',
                                }}
                            >
                                <img alt={'PointClickCare Logo'} width={20} height={20} src={'/pcc_logo150.png'} />
                                <span style={{marginLeft: 20}}>Sign in with <b>PointClickCare</b></span>
                            </ButtonBase>
                        </HBox>
                    </CardContent>


                </Card>

                <Accordion>
                    <AccordionSummary
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography>Maintenance Log In</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <TLForm onSubmit={formik.submitForm}>
                            <Stack spacing={2}>
                                <FormikTextField
                                    formik={formik}
                                    placeholder={'Username'}
                                    name={'username'}
                                    label={'Username'}
                                    type={'username'}
                                    autoComplete={'username'}
                                />
                                <FormikTextField
                                    formik={formik}
                                    placeholder={'Password'}
                                    name={'password'}
                                    label={'Password'}
                                    type={'password'}
                                    autoComplete={'password'}
                                />
                                <Stack spacing={2}>
                                    <Button
                                        disabled={isLoading}
                                        variant={'contained'}
                                        onClick={formik.submitForm}
                                    >
                                        Log in
                                    </Button>
                                </Stack>
                            </Stack>

                        </TLForm>
                    </AccordionDetails>
                </Accordion>
            </Stack>

        </HBox>

    )
}
