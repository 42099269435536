import {
    PaginationContext,
    UsePaginationArgs,
    StatePagination,
} from './pagination.types'
import {useNavigate} from "react-router-dom";

const ITEMS_PER_PAGE = 20

export function useRouteBasedPagination(
    itemsPerPage: number = ITEMS_PER_PAGE,
    pageQueryName: string = 'page'
): StatePagination {

    const query = new URLSearchParams(window.location.search)
    const location = window.location
    const navigate = useNavigate();
    const page = query.get(pageQueryName) ? parseInt(query.get(pageQueryName) as string) : 0;

    const setPage = (page: number) => {
        query.set(pageQueryName, page.toString())
        navigate({pathname: location.pathname, search: query.toString()})

    }

    return {
        page,
        setPage,
        itemsPerPage,
        paginationFilters: {
            offset: page * itemsPerPage,
            limit: itemsPerPage,
        },
    }
}

export function usePaginationContext(
    params: UsePaginationArgs
): PaginationContext {
    const { data, itemsPerPage = ITEMS_PER_PAGE, page, setPage } = params

    const count = data?.count || 0

    const startIndex = Math.min(count, page * itemsPerPage) + 1
    const endIndex = Math.max(
        startIndex,
        Math.min(count, startIndex + itemsPerPage - 1)
    )
    const pages = Math.ceil(count / itemsPerPage)

    const previousPage = page > 0 ? () => setPage(page - 1) : undefined
    const nextPage = page < pages - 1 ? () => setPage(page + 1) : undefined

    return {
        startIndex,
        endIndex,
        count,
        previousPage,
        nextPage,
        pages,
        page,
        setPage,
    }
}
