import React from 'react';
import {usePaginationContext, useRouteBasedPagination} from "../../../common/pagination/pagination";
import {Box, Card, CardContent, Stack, Typography} from "@mui/material";
import StateLoadingWrapper from "../../common/StateLoadingWrapper/StateLoadingWrapper";
import HBox from "../../common/box/HBox/HBox";
import PaginationChevrons from "../../common/PaginationChevrons/PaginationChevrons";
import {useListConditionsQuery} from "../../../store/api/condition";

interface PatientConditionsListProps {
    patientId: string;
}

function PatientConditionsList(argProps: PatientConditionsListProps): React.ReactElement {

    const {patientId} = argProps;
    const pagination = useRouteBasedPagination(5, 'condPage');
    const conditionsState = useListConditionsQuery({
        ...pagination.paginationFilters,
        patient: patientId
    });
    const conditions = conditionsState?.data?.results;
    const paginationContext = usePaginationContext({
        ...pagination,
        data: conditionsState.data,
    })
    return (
        <Stack spacing={1}>
            <Typography variant={'h5'}>♿ Conditions</Typography>
            <StateLoadingWrapper state={conditionsState}>
                <Stack spacing={1} alignItems="flex-start">
                    {
                        !!conditions && conditions.map(condition => {

                            return <Card key={condition.id} style={{width: '100%'}}>
                                <CardContent>
                                    <Typography variant={"caption"}>{condition.icd10}</Typography>
                                    <Typography variant={"h6"}>{condition.icd10Description}</Typography>
                                    <Box mt={1}>
                                        <Typography variant={'body2'}>
                                            {condition.comments}
                                        </Typography>
                                    </Box>
                                </CardContent>
                            </Card>
                        })
                    }
                    {conditions && !conditions.length && <HBox c mt={2}>
                        <Typography variant={'caption'}>No conditions found for this patient. 🔍</Typography>
                    </HBox>
                    }
                </Stack>
            </StateLoadingWrapper>


            <Box m={1}>
                <PaginationChevrons showPage isFetching={conditionsState.isFetching} context={paginationContext}/>
            </Box>

        </Stack>
    );

}

export default PatientConditionsList