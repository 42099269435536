import {baseApi} from "./base";
import {buildRTKAPI} from "../builder";
import {Patient, PatientListParams} from "../../models/patient";


const api = baseApi.injectEndpoints({
    endpoints: (builder) => {
        const api = buildRTKAPI<
            Patient,
            PatientListParams
        >(
            builder,
            "Patient",
            "/patients",
            {
                // Options
            }
        );

        return {
            getPatient: api.getItem,
            listPatients: api.getList,
        };
    },
});

export const {
    useGetPatientQuery,
    useListPatientsQuery,
} = api;
