import React from 'react'

interface NIProps extends React.FormHTMLAttributes<HTMLFormElement> {
    onSubmit: () => unknown
}

function TLForm(props: NIProps): React.ReactElement {
    function onKeyUpCapture(e: React.KeyboardEvent<HTMLFormElement>) {
        e.code === 'Enter' && props.onSubmit()
    }

    return <form onKeyUpCapture={onKeyUpCapture}>{props.children}</form>
}

export default TLForm
