import React from 'react'
import { PaginationChevronsProps } from './PaginationChevrons.types'
import { IconButton } from '@mui/material'
import { ChevronLeft, ChevronRight } from '@mui/icons-material'
import HBox from '../box/HBox/HBox'
import VBox from '../box/VBox/VBox'

const PaginationChevrons: React.FunctionComponent<PaginationChevronsProps> = ({
    isFetching,
    context: { previousPage, nextPage, pages, page },
    showPage = false,
}) => {
    return (
        <HBox data-testid={'PaginationChevrons'}>
            {previousPage ? (
                <IconButton
                    aria-label="Previous page button"
                    color={'primary'}
                    disabled={isFetching}
                    onClick={previousPage}
                >
                    <ChevronLeft />
                </IconButton>
            ) : null}

            {!!(showPage && pages && pages > 1) && (
                <VBox center>
                    <HBox
                        style={{
                            fontWeight: 'bold',
                        }}
                    >
                        {page + 1} / {pages}
                    </HBox>
                </VBox>
            )}

            {nextPage ? (
                <IconButton
                    aria-label="Next page button"
                    color={'primary'}
                    disabled={isFetching}
                    onClick={nextPage}
                >
                    <ChevronRight />
                </IconButton>
            ) : null}
        </HBox>
    )
}

export default PaginationChevrons
