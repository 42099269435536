import React from 'react';
import {Box, Card, CardContent, CardProps, Divider} from "@mui/material";
import {useGetPatientQuery} from "../../../store/api/patient";
import StateLoadingWrapper from "../../common/StateLoadingWrapper/StateLoadingWrapper";
import {PatientAddress} from "../../../models/patient";

interface PatientGeoInfoCardProps extends CardProps {
    patientId: string;
}


interface AddressRowsProps {
    address: PatientAddress
}

function AddressRows(props: AddressRowsProps){

    const {address} = props;

    return <Box mt={2}>
        <div>{address.addressLine1}</div>
        <div>{address.addressLine2}</div>
        <div>{address.city}, {address.state || '--'}</div>
        <div>{address.country}</div>
        <div>{address.postalCode}</div>
    </Box>
}

function PatientGeoInfoCard(argProps: PatientGeoInfoCardProps): React.ReactElement {
    const {patientId, ...props} = argProps;

    const patientState = useGetPatientQuery(patientId);
    const patient = patientState.data;

    const currentAddress = patient?.legalMailingAddress;
    const previousAddress = patient?.previousAddress;
    const hasAnyAddress = patient && (currentAddress || previousAddress);

    return (
        <StateLoadingWrapper state={patientState}>
            {!!patient &&
            <Card style={{width: '100%'}} {...props}>
                <CardContent>
                    {hasAnyAddress ? <>
                    {currentAddress && <>
                    <Divider> Current Address </Divider>
                    <AddressRows address={currentAddress} />
                    </>}

                    {previousAddress && <>
                        <Divider> Previous Address </Divider>
                        <AddressRows address={previousAddress} />
                    </>}

                    </> : <div>
                        No patient address information
                    </div>}
                </CardContent>
            </Card>}
        </StateLoadingWrapper>
    );
}

export default PatientGeoInfoCard