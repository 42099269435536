import React from 'react';
import {Card, CardContent, CardProps, Chip, Stack, Typography} from "@mui/material";
import StateLoadingWrapper from "../../common/StateLoadingWrapper/StateLoadingWrapper";
import {useGetPatientQuery} from "../../../store/api/patient";
import HBox from "../../common/box/HBox/HBox";
import {PatientCoverageData} from "../../../models/patient";
import {getFormattedDate} from "../../../common/dateUtils";

interface PatientCoverageCardProps extends CardProps{
    patientId: string;
}

function CoverageGroup({coverage}: {coverage: PatientCoverageData}) {
    return   <Stack spacing={1}  alignItems="flex-start">

        <Typography variant={'caption'}>
            Covered from <b>{getFormattedDate(coverage.effectiveFromDateTime)}</b> to <b>{getFormattedDate(coverage.effectiveToDateTime)}</b>
        </Typography>

        {(coverage.payers || []).map(payer => {
            return <Card key={payer.payerId} style={{width: '100%'}}>
                <CardContent>
                    <Typography variant={"caption"}>{coverage.coverageId} - {payer.payerId}</Typography>
                    <Typography variant={"h6"}>{payer.payerName}</Typography>
                    <Stack  direction={'row'} spacing={1}>
                        {payer.payerRank && <Chip size={'small'} label={`🔼 ${payer.payerRank}`} />}
                        {payer.payerType && <Chip size={'small'} label={`📃 ${payer.payerType}`} />}
                        {payer.pps ?
                            <Chip size={'small'} color={'primary'} label={`PPS ✅`} /> :
                            <Chip size={'small'} label={`PPS ❌`} />}
                    </Stack>
                </CardContent>
            </Card>
        })}
    </Stack>
}


function PatientCoverageCard(argProps: PatientCoverageCardProps): React.ReactElement {
    const {patientId} = argProps;

    const patientState = useGetPatientQuery(patientId);
    const coverage = patientState.data?.coverageData;

    return     <StateLoadingWrapper state={patientState}>
        {coverage && <CoverageGroup coverage={coverage} />}
    {!coverage && <HBox mt={2}>
        <Typography>
            No coverage data found for this patient. 📃
        </Typography>
    </HBox>}

    </StateLoadingWrapper>




}

export default PatientCoverageCard