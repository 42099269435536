import {buildRTKAPI} from "../builder";
import {baseApi} from "./base";
import {PCCWebHook, SimulatedEvent, Webhook} from "../../models/webhook";
import {DjangoListParams} from "../django.types";
import {PCCListRequestParams, PCCListResponse} from "../../models/pcc";

const api = baseApi.injectEndpoints({
    endpoints: (builder) => {
        const api = buildRTKAPI<
            Webhook,
            DjangoListParams
        >(
            builder,
            "Webhook",
            "/webhooks",
            {
                // Options
            }
        );

        return {
            listWebhooks: api.getList,
            listPCCWebhooks: builder.query<PCCListResponse<PCCWebHook>, PCCListRequestParams>({
                query: (params) => ({
                    url: `/webhooks/pcc_webhooks/`,
                    params,
                }),

                providesTags: ['PCCWebhook'],
            }),

            createWebhook: builder.mutation<Webhook, {}>({
                query: (data) => ({
                    url: `/webhooks/`,
                    method: 'POST',
                    body: data,
                }),
                invalidatesTags: [
                    'Webhook',
                    'PCCWebhook'
                ]
            }),

            simulateEvent: builder.mutation<null, SimulatedEvent>({
                query: (data) => ({
                    url: `/webhooks/simulate_event/`,
                    method: 'POST',
                    body: data,
                }),

            })

        };
    },
});

export const {
    useListPCCWebhooksQuery,
    useCreateWebhookMutation,
    useSimulateEventMutation,
} = api;
