import React from 'react';
import {
    Box,
    Chip, CircularProgress,
    InputBase,
    Paper,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography
} from "@mui/material";
import {useListPatientsQuery} from "../../../store/api/patient";
import {usePaginationContext, useRouteBasedPagination} from "../../../common/pagination/pagination";
import StateLoadingWrapper from "../../common/StateLoadingWrapper/StateLoadingWrapper";
import TLNavLink from "../../common/TLNavLink/TLNavLink";
import PaginationChevrons from "../../common/PaginationChevrons/PaginationChevrons";
import {getShortenedUUID} from "../../../common/uuidUtils";
import {getRelativeDateTimeFromString} from "../../../common/dateUtils";
import {Search} from "@mui/icons-material";
import HBox from "../../common/box/HBox/HBox";
import VBox from "../../common/box/VBox/VBox";
import {useRouteBasedSearch} from "../../../common/search";


function PatientsList(): React.ReactElement {

    const statePagination = useRouteBasedPagination(10)

    const {search, searchState, setSearch} = useRouteBasedSearch();
    const patientsState = useListPatientsQuery({
        ...statePagination.paginationFilters,
        ...((search?.length) ? {search} : {})
    });

    const paginationContext = usePaginationContext({
        ...statePagination,
        data: patientsState.data,
    })

    const patients = patientsState.data?.results;

    return <StateLoadingWrapper state={patientsState}>

        <Stack>
            <Typography variant={'h4'}>
                Patients
            </Typography>

            <HBox mt={1} mb={1}>
                <Paper
                    component="form"
                    sx={{p: '2px 4px', display: 'flex', alignItems: 'center', width: 400}}
                >
                    <InputBase
                        sx={{ml: 1, flex: 1}}
                        placeholder="Search patiens by name, ids, email, phone, etc."
                        inputProps={{'aria-label': 'search google maps'}}
                        value={searchState}
                        onChange={(e) => setSearch(e.target.value)}
                        onSubmit={(e) => {
                            e.preventDefault()
                        }}
                        onKeyDown={(e) => {
                            if (e.key === 'Enter') {

                                e.preventDefault();
                            }
                        }}
                    />
                    <Search/>
                </Paper>

                <VBox center ml={2}>
                    {(patientsState.isFetching || patientsState.isLoading) && <CircularProgress size={20}/>}
                </VBox>


            </HBox>


            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>
                            Internal ID
                        </TableCell>
                        <TableCell>
                            Record Number
                        </TableCell>
                        <TableCell>
                            Name
                        </TableCell>
                        <TableCell>
                            Facility
                        </TableCell>
                        <TableCell>
                            Stats
                        </TableCell>
                        <TableCell>
                            Onboarded Date
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>

                    {
                        !patientsState.data?.results?.length && <TableRow>
                            <TableCell colSpan={6}>
                                No patients found 👀.
                            </TableCell>
                        </TableRow>
                    }

                    {!!patients && patients.map((patient) => {

                        return <TableRow key={patient.id}>
                            <TableCell>
                                <TLNavLink to={`/patients/${patient.id}`}>
                                    {getShortenedUUID(patient.id)}
                                </TLNavLink>
                            </TableCell>
                            <TableCell>{patient.medicalRecordNumber}</TableCell>
                            <TableCell>{patient.firstName} {patient.lastName}</TableCell>
                            <TableCell>{patient.facilityName}</TableCell>
                            <TableCell>
                                <Chip size={'small'} label={`💉 ${patient.medicationsCount}`}/>&nbsp;
                                <Chip size={'small'} label={`♿ ${patient.conditionsCount}`}/>&nbsp;
                            </TableCell>
                            <TableCell>{getRelativeDateTimeFromString(patient.onboardedAt)}</TableCell>
                        </TableRow>
                    })}


                </TableBody>
            </Table>

            <Box ml={2} mt={1}>
                <PaginationChevrons
                    context={paginationContext}
                    isFetching={patientsState.isFetching}
                    showPage
                />
            </Box>
        </Stack>
    </StateLoadingWrapper>
}

export default PatientsList