import { EndpointBuilder } from '@reduxjs/toolkit/dist/query/endpointDefinitions'
import { BaseQueryFn } from '@reduxjs/toolkit/query'
import { DjangoListResponse } from './django.types'

export type BaseModel = {
    id: string;
}

export interface InvalidateKP<ItemType> {
    itemField?: keyof ItemType
    tagType: string
}

export interface BuilderOptions<ItemModel> {
    invalidateOnAll?: InvalidateKP<ItemModel>[]
    invalidateOnMutate?: InvalidateKP<ItemModel>[]
    invalidateOnCreate?: InvalidateKP<ItemModel>[]
    invalidateOnUpdate?: InvalidateKP<ItemModel>[]
    invalidateOnDelete?: InvalidateKP<ItemModel>[]
}

export function getQueryFromParams<P>(params: P): string {
    return Object.entries(params as object)
        .filter(([key, value]) => {
            return !(key === 'search' && !value)
        })
        .map(
            ([key, value]) =>
                `${key}=${typeof value == 'string' ? encodeURI(value) : value}`
        )
        .join('&')
}

export function withParams<P>(url: string, params?: P): string {
    const pString =
        params && Object.keys(params).length > 0
            ? getQueryFromParams(params)
            : ''
    return pString.length > 0 ? `${url}?${pString}` : url
}

function getInvalidations<ItemType>(
    item: Partial<ItemType>,
    rules?: InvalidateKP<ItemType>[]
): { id: string | number | undefined; type: string }[] {
    if (!rules) return []
    return rules.map((rule) => {
        const fieldVal = rule.itemField && item[rule.itemField]
        return {
            id:
                typeof fieldVal === 'number' || typeof fieldVal === 'string'
                    ? fieldVal
                    : 'LIST',
            type: rule.tagType,
        }
    })
}

export function buildRTKAPI<
    ItemModel extends BaseModel,
    LP,
    CreateModel = Partial<ItemModel>,
    UpdateModel = Partial<ItemModel>,
>(
    builder: EndpointBuilder<BaseQueryFn, string, string>,
    name: string,
    url: string,
    options?: BuilderOptions<ItemModel>
) {
    return {
        getItem: builder.query<ItemModel, number | string>({
            query: (id) => `${url}/${id}/`,
            providesTags: (result) =>
                result
                    ? [
                          { type: name, id: 'LIST' },
                          { type: name, id: result.id },
                      ]
                    : [],
        }),

        getList: builder.query<DjangoListResponse<ItemModel>, LP>({
            query: (params?: LP) => withParams(`${url}/`, params),
            providesTags: (result) => [
                { type: name, id: 'LIST' },
                ...(result?.results || []).map((i) => ({
                    type: name,
                    id: i.id,
                })),
            ],
        }),

        updateItem: builder.mutation<ItemModel, BaseModel & UpdateModel>({
            query(data) {
                const { id, ...body } = data
                return {
                    url: `${url}/${id}/`,
                    method: 'PATCH',
                    body,
                }
            },
            invalidatesTags: (result) =>
                result
                    ? [
                          { type: name, id: result.id },
                          { type: name, id: 'LIST' },
                          ...getInvalidations(result, options?.invalidateOnAll),
                          ...getInvalidations(
                              result,
                              options?.invalidateOnUpdate
                          ),
                      ]
                    : [],
        }),

        createItem: builder.mutation<ItemModel, CreateModel>({
            query(data) {
                return {
                    url: `${url}/`,
                    method: 'POST',
                    body: data,
                }
            },
            invalidatesTags: (result) =>
                result
                    ? [
                          { type: name, id: 'LIST' },
                          ...getInvalidations(result, options?.invalidateOnAll),
                          ...getInvalidations(
                              result,
                              options?.invalidateOnCreate
                          ),
                      ]
                    : [],
        }),

        removeItem: builder.mutation<ItemModel, Partial<ItemModel>>({
            query(data) {
                const { id } = data
                return {
                    url: `${url}/${id}/`,
                    method: 'DELETE',
                }
            },
            invalidatesTags: (result, error, q) =>
                !error
                    ? [
                          { type: name, id: q.id },
                          { type: name, id: 'LIST' },
                          ...getInvalidations(q, options?.invalidateOnAll),
                          ...getInvalidations(q, options?.invalidateOnUpdate),
                      ]
                    : [],
        }),
    }
}
