import {useEffect, useState} from "react";
import {useDebounce} from "usehooks-ts";
import {useNavigate} from "react-router-dom";

interface RouteBasedSearchParams {
    debounceInterval?: number
}

export function useRouteBasedSearch(params?: RouteBasedSearchParams){

    const {debounceInterval = 500} = params || {};

    const [search, setSearch] = useState('');
    const navigate = useNavigate();
    const query = new URLSearchParams(window.location.search);
    const debouncedSearch  = useDebounce(search, debounceInterval);

    const querySearch = query.get('search') || '';

    useEffect(() => {

        const location = window.location;
        const currentQueryParams = new URLSearchParams(window.location.search);

        if (currentQueryParams.has('page')){
            currentQueryParams.delete('page');
        }

        if (debouncedSearch.trim().length){
            currentQueryParams.set('search', debouncedSearch.trim());
            navigate({pathname: location.pathname, search: currentQueryParams.toString()})
        } else {
            if (currentQueryParams.has('search')){
                currentQueryParams.delete('search');
                navigate({pathname: location.pathname, search: currentQueryParams.toString()})
            }
        }
    }, [debouncedSearch, navigate]);

    return {
        search: querySearch,
        searchState: search,
        setSearch,
    }

}