import { FormikProps } from 'formik'
import {
    alpha,
    FormControl,
    InputBase,
    InputBaseProps,
    InputLabel,
    styled,
    Typography,
} from '@mui/material'
import React from 'react'

export const TextFieldBase = styled(InputBase)(({ theme }) => ({
    'label + &': {
        marginTop: theme.spacing(3),
        fontWeight: 400,
    },
    '& .MuiInputBase-input': {
        borderRadius: 10,
        position: 'relative',
        backgroundColor: theme.palette.mode === 'light' ? '#F6F9FF' : '#2b2b2b',

        fontSize: 16,
        //width: "auto",
        padding: '10px 12px',
        transition: theme.transitions.create([
            'border-color',
            'background-color',
            'box-shadow',
        ]),
        fontWeight: 400,
        color: '#324168',
        // Use the system font instead of the default Roboto font.
        fontFamily: [
            'Inter',
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
        '&:focus': {
            boxShadow: `${alpha(
                theme.palette.primary.main,
                0.25
            )} 0 0 0 0.2rem`,
            borderColor: theme.palette.primary.main,
        },
    },
}))

interface FormikTextFieldProps<T> {
    formik: FormikProps<T>
    name: keyof T
    label?: string
    showLabel?: boolean
}

function getDefaultFieldValueForType(type: string | undefined): string {
    switch (type) {
        default:
            return ''
    }
}

function getDefaultDataValueForType(type: string | undefined): string | null {
    switch (type) {
        case 'number':
            return null
        default:
            return ''
    }
}
function FormikTextField<T>(
    props: FormikTextFieldProps<T> & InputBaseProps
): React.ReactElement {
    const { formik, name, showLabel = true, ...otherProps } = props

    const value = formik.values[name]
    const defaultValue = getDefaultFieldValueForType(props.type)
    const valueIsNull = value === null || value === undefined
    const fieldError = formik.errors[name]
    const onChange = (
        e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
    ) => {
        const newVal = e.target.value
        if (newVal === '' || newVal === null || newVal === undefined) {
            formik.setFieldValue(name, getDefaultDataValueForType(props.type))
        } else {
            formik.setFieldValue(name, newVal)
        }
    }

    return (
        <FormControl variant={'standard'} fullWidth={props.fullWidth}>
            {showLabel && (
                <InputLabel shrink htmlFor={name as string}>
                    {props.label || name}
                </InputLabel>
            )}

            <TextFieldBase
                id={name as string}
                name={name as string}
                autoComplete={'off'}
                value={valueIsNull ? defaultValue : value}
                onChange={onChange}
                error={Boolean(formik.errors[name] as string)}
                {...otherProps}
            />
            {fieldError && (
                <Typography variant={'subtitle2'} color={'danger'}>
                    {fieldError as string}
                </Typography>
            )}
        </FormControl>
    )
}

export default FormikTextField
