import React from 'react'
import { Box, BoxProps } from '@mui/material'

interface HBoxProps {
    columnOnMobile?: boolean
    c?: boolean
    l?: boolean
    r?: boolean
}

function getJustifyContent(
    c: boolean,
    l: boolean,
    r: boolean
): 'center' | 'flex-start' | 'flex-end' | undefined {
    if (c) return 'center'
    if (l) return 'flex-start'
    if (r) return 'flex-end'

    return undefined
}

const HBox: React.FC<HBoxProps & BoxProps> = (props) => {
    const {
        children,
        columnOnMobile = false,
        c = false,
        l = false,
        r = false,
        ...op
    } = props

    const justifyContent = getJustifyContent(c, l, r)

    return (
        <Box
            flexDirection={{ xs: columnOnMobile ? 'column' : 'row', lg: 'row' }}
            display={'flex'}
            justifyContent={justifyContent}
            {...op}
        >
            {props.children}
        </Box>
    )
}

export default HBox
