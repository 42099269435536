import React from 'react';
import HBox from "../../common/box/HBox/HBox";
import {Alert, AlertTitle, Stack, Typography} from "@mui/material";


interface PCIAPIError {
    id: string;
    code: string;
    status: string;
    title: string;
    detail: string;
}
interface PCCAPIErrorResponse {
    status: number;
    data: {
        errors: PCIAPIError[]
    }
}


interface AppErrorAlertProps {
    error: unknown;
}

function PCCError({error} : {error: PCCAPIErrorResponse}) : React.ReactElement {

    return <HBox c width={'100%'}>
        <Stack spacing={1}>
            {error.data.errors.map(error=><Alert key={error.code} severity={'error'}>
                <AlertTitle>{error.title}</AlertTitle>
                <Typography>{error.detail}</Typography>
            </Alert>)}
        </Stack>
    </HBox>
}

function AppErrorAlert(argProps: AppErrorAlertProps): React.ReactElement {
    const {error} = argProps;

    if ((error as PCCAPIErrorResponse)?.status && (error as PCCAPIErrorResponse)?.data?.errors){
        return <PCCError error={error as PCCAPIErrorResponse} />
    }

    if (typeof((error as {data?: unknown}).data) === 'string'){
        return  <Alert severity={'error'}>
            {(error as {data: string}).data}
        </Alert>
    }

    return (
        <Alert severity={'error'}>Unknown Error</Alert>
    );
}

export default AppErrorAlert