import { toast } from 'react-toastify'
import { FormikProps } from 'formik'

export function handleErrorResponse<T>(
    response: { error: object },
    formik: FormikProps<T>,
    error: string = 'Unexpected error while making an API request'
) {
    if ('data' in response.error) {
        if (Array.isArray(response.error.data)) {
            response.error.data.forEach((e) => {
                toast.error(e)
            })
        } else if (
            typeof response.error.data === 'object' &&
            response.error.data !== null
        ) {
            for (const [key, value] of Object.entries(response.error.data)) {
                formik.setFieldError(key, value.join(''))
            }
        } else {
            console.dir(response)
            toast.error('Unexpected error response from server')
        }
    } else {
        console.dir(response)
        toast.error(error)
    }
}


export function handleErrorOrProceed<T>(
    response: object,
    formik?: FormikProps<T>,
    error: string = 'Unexpected error while making an API request'
) {
    if ('error' in response && typeof response.error === 'object') {

        if (formik){
            handleErrorResponse(response as { error: object }, formik, error)
        } else {
            toast.error(error);
            console.dir(response);
        }
        return false
    }
    return true
}
