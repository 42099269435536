import React from 'react';
import {AppUser} from "../../../models/appUser";
import {Button, Tooltip} from "@mui/material";
import {formatDistance, isPast} from 'date-fns';

interface OAuthTokenMenuItemProps {
    user: AppUser
}


function getTokenExpirationTitleForUser(user: AppUser){

    if (!user.tokenExpiration){
        return {
            tooltipText: "User is not authorised with PCC",
            buttonText: "No Token"
        }
    }

    const tokenDate = new Date(user.tokenExpiration);

    if (isPast(tokenDate)){
        return {
            buttonText: `OAuth ❌`,
            tooltipText: `OAuth Token Expired ${formatDistance(tokenDate, new Date())} ago`
        }
    } else {
        return {
            buttonText: `OAuth ✅`,
            tooltipText: `OAuth Token will expire in ${formatDistance(tokenDate, new Date())}`
        }
    }

}

function OAuthTokenAppBarItem(argProps: OAuthTokenMenuItemProps): React.ReactElement {

    const {user} = argProps;


    const {tooltipText, buttonText} = getTokenExpirationTitleForUser(user);

    return <Tooltip title={tooltipText}>
        <Button color={'inherit'}>
            {buttonText}
        </Button>
    </Tooltip>
}

export default OAuthTokenAppBarItem