import {buildRTKAPI} from "../builder";
import {baseApi} from "./base";
import {Facility, FacilityListParams} from "../../models/facility";


const api = baseApi.injectEndpoints({
    endpoints: (builder) => {
        const api = buildRTKAPI<
            Facility,
            FacilityListParams
        >(
            builder,
            "Facility",
            "/facilities",
            {
                // Options
            }
        );

        return {
            listFacilities: api.getList,
        };
    },
});

export const {
    useListFacilitiesQuery
} = api;
