import React from 'react'
import { useGetCurrentUserQuery } from '../../store/api/session'
import { UnauthorizedRouter } from '../auth/UnauthorizedRouter/UnauthorizedRouter'
import AuthorizedRouter from '../platform/AuthorizedRouter/AuthorizedRouter'

function App(): React.ReactElement {
    const { data, isLoading } = useGetCurrentUserQuery()

    // Terminate earlier in case it's loading
    if (!data?.username && isLoading) {
        return <div>Loading...</div>
    }

    return data?.username ? <AuthorizedRouter /> : <UnauthorizedRouter />
}

export default App
