import React from 'react';
import {Box, Button} from "@mui/material";
import {useNavigate} from "react-router-dom";
import HBox from "../../common/box/HBox/HBox";
import {useIsSuperUser} from "../../../common/isSuperuser";

interface NavItem {
    label: string;
    path: string;
    isActive?: (pathname: string) => boolean;
    default?: boolean;
}

const genericNavItems: NavItem[] = [

    {
        label: "Patients",
        path: "/patients",
        default: true,
    },

    {
        label: "Facilities",
        path: "/facilities",
        isActive: (pathname: string) => pathname.startsWith("/facilities")
    },

]

const adminNavItems: NavItem[] = [

    {
        label: "Patients",
        path: "/patients",
        default: true,
    },

    {
        label: "Facilities",
        path: "/facilities",
        isActive: (pathname: string) => pathname.startsWith("/facilities")
    },

    {
        label: "Admin",
        path: "/admin",
        isActive: (pathname: string) => pathname.startsWith("/admin")
    }

]

function AppBarNavigationButtons(): React.ReactElement {

    const isSuperUser = useIsSuperUser();
    const navItems = isSuperUser ? adminNavItems : genericNavItems;

    const navigate = useNavigate();
    const activeRoute = navItems.find((item) =>
            item.isActive ? item.isActive(window.location.pathname) : false) ||
        navItems.find((item) => item.default);

    function onNavClicked(item: NavItem) {
        return function (e: React.MouseEvent) {
            e.stopPropagation();
            navigate(item.path);
        }
    }

    return <HBox ml={2}>
        {navItems.map((item) => {
            const isSelected = activeRoute === item;
            return (
                <Box key={item.path} ml={2}>
                    <Button
                        key={item.path}
                        onClick={onNavClicked(item)}
                        color={isSelected ? "primary" : "inherit"}
                    >
                        {item.label}
                    </Button>
                </Box>
            )
        })}
    </HBox>
}

export default AppBarNavigationButtons