import React from 'react'
import { Box, BoxProps } from '@mui/material'

interface VBoxProps {
    center?: boolean
}

const VBox: React.FC<VBoxProps & BoxProps> = (props) => {
    const { children, center = false, ...op } = props
    return (
        <Box
            flexDirection={'column'}
            justifyContent={center ? 'center' : undefined}
            display={'flex'}
            {...op}
        >
            {props.children}
        </Box>
    )
}

export default VBox
