import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import Cookies from 'js-cookie'

// initialize an empty api service that we'll inject endpoints into later as needed
export const baseApi = createApi({
    reducerPath: 'api',
    baseQuery: fetchBaseQuery({
        baseUrl: process.env['REACT_APP_API_ROOT'] || '',
        prepareHeaders: (headers) => {

            const csrfToken = Cookies.get('csrftoken')
            if (csrfToken) {
                headers.set('X-CSRFToken', csrfToken)
            }

            return headers
        },
        credentials: 'include',

    }),

    tagTypes: ['Session', 'PCCFacility', 'Facility', 'Patient', 'Medication', 'Condition', 'Webhook', 'PCCWebhook'],
    endpoints: () => ({}),
})
