import React from 'react'
import { BoxProps } from '@mui/material'
import VBox from '../VBox/VBox'
import HBox from '../HBox/HBox'

export interface CBoxProps {
    outerBoxProps?: BoxProps
    innerBoxProps?: BoxProps
    children: BoxProps['children']
}

const CBox: React.FC<CBoxProps> = (props) => {
    const { children, outerBoxProps = {}, innerBoxProps = {} } = props
    return (
        <VBox justifyContent={'center'} {...outerBoxProps} height={'100%'}>
            <HBox justifyContent={'center'} {...innerBoxProps}>
                {children}
            </HBox>
        </VBox>
    )
}

export default CBox
