import {Alert, Box, Grid, Stack, Typography} from '@mui/material';
import React from 'react';
import {useParams} from "react-router-dom";
import PatientCard from "../PatientCard/PatientCard";
import HBox from "../../common/box/HBox/HBox";
import PatientMedicationsList from "../PatientMedicationsList/PatientMedicationsList";
import PatientConditionsList from "../PatientConditionsList/PatientConditionsList";
import PatientCoverageCard from "../PatientCoverageCard/PatientCoverageCard";
import VBox from "../../common/box/VBox/VBox";
import PatientGeoInfoCard from "../PatientGeoInfoCard/PatientGeoInfoCard";



function PatientDetailsPage(): React.ReactElement {

    const {patientId} = useParams<{patientId: string}>()


    if (!patientId){
        return <HBox>
            <Alert severity={'warning'}>
                No patient selected
            </Alert>
        </HBox>
    }

    return (
        <Grid container>
            <Grid item xs={12} md={6} lg={4}>

                <Stack spacing={4}>
                    <HBox m={1}>
                        <PatientCard patientId={patientId} />
                    </HBox>
                    <VBox m={1}>
                        <Typography variant={'h5'}>🏢 Patient Address</Typography>
                        <br/>
                        <PatientGeoInfoCard patientId={patientId} />
                    </VBox>
                    <VBox m={1} mt={5}>
                        <Typography variant={'h5'}>📃 Coverage Information</Typography>
                        <br/>
                        <PatientCoverageCard patientId={patientId} />
                    </VBox>
                </Stack>


            </Grid>

            <Grid item container xs={12} md={6} lg={8}>

                <Grid item xs={12} md={12} lg={6}>
                    <Box m={1}>
                        <PatientMedicationsList patientId={patientId} />
                    </Box>
                </Grid>

                <Grid item xs={12} md={12} lg={6}>
                    <Box m={1}>
                        <PatientConditionsList patientId={patientId} />
                    </Box>
                </Grid>

            </Grid>
        </Grid>
    );
}

export default PatientDetailsPage