import { baseApi } from './base'
import { AppUser } from '../../models/appUser'
import { LoginForm } from '../../models/session'
import { useDispatch } from 'react-redux'
import { toast } from 'react-toastify'

const sessionApi = baseApi.injectEndpoints({
    endpoints: (builder) => ({
        getCurrentUser: builder.query<AppUser, void>({
            query: () => `/users/auth/get_user/`,
            providesTags: () => ['Session'],
        }),

        login: builder.mutation<AppUser, LoginForm>({
            query: (body) => ({
                url: `/users/auth/login/`,
                method: 'POST',
                body,
            }),
            invalidatesTags: (r) => (!!r ? ['Session'] : []),
        }),

        logout: builder.mutation<{}, void>({
            query: () => ({ url: `/users/auth/logout/`, method: 'POST' }),
            invalidatesTags: (resp) => (resp ? ['Session'] : []),
        }),
    }),
    overrideExisting: false,
})

const useLogoutAction = (): [
    () => Promise<void>,
    ReturnType<typeof sessionApi.endpoints.logout.useMutation>[1],
] => {
    const [requestLogout, state] = sessionApi.useLogoutMutation()
    const dispatch = useDispatch()
    const asyncLogout = async (): Promise<void> => {
        const response = await requestLogout()
        if ('error' in response) {
            console.dir(response)
            toast.error('Could not log you out')
            return
        }
        dispatch(sessionApi.util.resetApiState())
    }

    return [asyncLogout, state]
}

const { useGetCurrentUserQuery, useLoginMutation } = sessionApi

export { useLogoutAction, useGetCurrentUserQuery, useLoginMutation }
