import React from 'react';
import {Button, Card, CardActions, CardContent, Divider, Menu, MenuItem, Stack, Typography} from "@mui/material";
import {useGetPatientQuery} from "../../../store/api/patient";
import StateLoadingWrapper from "../../common/StateLoadingWrapper/StateLoadingWrapper";
import {theme} from "../../../theme/theme";
import {useIsSuperUser} from "../../../common/isSuperuser";
import PopupState, {bindMenu, bindTrigger} from "material-ui-popup-state";
import {useSimulateEventMutation} from "../../../store/api/webhook";
import {handleErrorOrProceed} from "../../../common/formik";
import {toast} from "react-toastify";


interface PatientCardProps {
    patientId: string;
}

const patientEventTypes = [
    'patient.admit',
    'patient.readmit',
    'patient.cancelAdmit',
    'patient.discharge',
    'patient.cancelDischarge',
    'patient.transfer',
    'patient.cancelTransfer',
    'patient.updateResidentInfo',
    'patient.leave',
    'patient.returnFromLeave',
    'patient.cancelLeave',
    'patient.cancelReturnFromLeave',
    'patient.preAdmit',
    'patient.outpatientToInpatient',
    'patient.inpatientToOutpatient',
    'patient.updateContactInfo',
    'patient.updateAccount',
    'patient.updateAccountOnReturnFromLeave',
    'patient.updateHIEPrivacyConsent',
    'allergy.add',
    'allergy.strikeout',
    'allergy.update',
    'claim.export',
    'condition.add',
    'condition.strikeout',
    'condition.update',
    'patient.pendingPatientAccepted',
    'patient.pendingPatientDeleted',
    'immunization.add',
    'immunization.strikeout',
    'immunization.update',
    'incident.add',
    'incident.strikeout',
    'incident.update',
    'medication.add',
    'medication.discontinue',
    'medication.strikeout',
    'medication.cancelDiscontinue',
    'medication.update',
    'medicalProfessional.add',
    'medicalProfessional.remove',
    'nutritionOrder.add',
    'nutritionOrder.discontinue',
    'nutritionOrder.hold',
    'nutritionOrder.strikeout',
    'nutritionOrder.updateNotes',
    'nutritionOrder.update',
    'observation.add',
    'observation.strikeout',
    'observation.warningGenerated',
    'painManagement.add',
    'painManagement.strikeout',
    'patientPhoto.Upload',
    'patientPhoto.Delete',
    'progressNote.add',
    'progressNote.strikeout',
    'labResults.add',
    'labResults.upload',
    'labResults.cancel',
    'labResults.strikeout',
    'labResults.partiallyresulted',
    'radiologyResults.add',
    'radiologyResults.upload',
    'radiologyResults.cancel',
    'radiologyResults.strikeout',
    'radiologyResults.partiallyresulted',
    'labResults.warningGenerated',
    'supplementOrder.add',
    'supplementOrder.discontinue',
    'supplementOrder.hold',
    'supplementOrder.strikeout',
    'supplementOrder.reactivateFromHold',
    'supplementOrder.cancelDiscontinue',
    'supplementOrder.updateNotes',
    'supplementOrder.update',
    'statements.export'

]


function PatientCard(argProps: PatientCardProps): React.ReactElement {
    const {patientId} = argProps;
    const isSuperUser = useIsSuperUser();
    const patientState = useGetPatientQuery(patientId);
    const patient = patientState.data;


    const [_simulateEvent, {isLoading: isSimulatingEvent}] = useSimulateEventMutation();

    async function simulateEvent(patientId: string, eventType: string) {
        const r = await _simulateEvent({patientId, eventType});
        if (handleErrorOrProceed(r)) {
            toast.success('Event simulated successfully');
        }
    }

    return (
        <StateLoadingWrapper state={patientState}>
            {patient && patient.id && <Card style={{width: '100%'}}>
                <CardContent>
                    <Typography variant={'h6'} textAlign={'left'}>{patient.firstName} {patient.lastName}</Typography>
                    <Typography variant={'caption'} color={theme.palette.secondary.main}
                                textAlign={'left'}>{patient.id}</Typography>

                    <br/>
                    <Divider>
                        <Typography variant={'caption'} color={theme.palette.secondary.main}
                                    textAlign={'left'}>Details</Typography>
                    </Divider>
                    <br/>
                    <Stack spacing={1}>
                        <Typography variant={'body2'}><b>Facility:</b> {patient.facilityName}</Typography>
                        <Typography variant={'body2'}><b>MRN:</b> {patient.medicalRecordNumber}</Typography>
                        {patient.birthDate && <Typography variant={'body2'}><b>DOB:</b> {patient.birthDate}</Typography>}
                        {patient.phoneNumber && <Typography variant={'body2'}><b>Phone:</b> {patient.phoneNumber}</Typography>}
                        {patient.email && <Typography variant={'body2'}><b>Email:</b> {patient.email}</Typography>}
                        {patient.gender && <Typography variant={'body2'}><b>Gender:</b> {patient.gender}</Typography>}
                    </Stack>
                </CardContent>
                {isSuperUser && <CardActions>
                    <Stack direction={'row'} spacing={1} justifyContent={'flex-end'}>
                        <Button variant={'outlined'}
                                href={process.env['REACT_APP_API_ROOT'] + '/admin/patients/patient/' + patientId}
                                target={"_blank"}>
                            Open in admin 🕵️‍♀️
                        </Button>

                        <PopupState variant="popover" popupId="demo-popup-menu" disableAutoFocus={false}
                                    parentPopupState={null}>
                            {(popupState) => (
                                <React.Fragment>
                                    <Button variant="contained" {...bindTrigger(popupState)}
                                            disabled={isSimulatingEvent}>
                                        Simulate Event ⚡
                                    </Button>
                                    <Menu {...bindMenu(popupState)}>
                                        {patientEventTypes.map((eventType) => {
                                            return <MenuItem key={eventType} onClick={() => {
                                                simulateEvent(patientId, eventType);
                                                popupState.close();
                                            }}>
                                                {eventType}
                                            </MenuItem>
                                        })}
                                    </Menu>
                                </React.Fragment>
                            )}
                        </PopupState>
                    </Stack>
                </CardActions>}
            </Card>}

        </StateLoadingWrapper>
    );
}

export default PatientCard