import React from 'react'
import { createBrowserRouter, Outlet, RouterProvider } from 'react-router-dom'
import { Box, Stack } from '@mui/material'
import AppBar from "../AppBar/AppBar";
import PatientsPage from "../PatientsPage/PatientsPage";
import FacilitiesPage from "../FacilitiesPage/FacilitiesPage";
import PatientDetailsPage from "../PatientDetailsPage/PatientDetailsPage";
import AdminPage from "../AdminPage/AdminPage";

function AuthorizedLayout(): React.ReactElement {
    return (
        <Stack>
            <AppBar />
            <Box mt={10}>
                <Outlet />
            </Box>
        </Stack>
    )
}

const router = createBrowserRouter([
    {
        path: '',
        element: <AuthorizedLayout />,

        children: [

            {
                path: 'facilities',
                element: <FacilitiesPage />,

            },

            {
                path: 'patients',
                children: [
                    {
                      path: ':patientId',
                      element: <PatientDetailsPage />,
                    },
                    {
                        index: true,
                        element: <PatientsPage />,
                    }
                ],
            },

            {
                path: 'admin',
                element: <AdminPage />,

            },

            // Home
            {
                index: true,
                element: <PatientsPage />,
            },
        ],
    },
])

function AuthorizedRouter() {
    return <RouterProvider router={router} />
}

export default AuthorizedRouter
