import React from 'react';
import {Box} from "@mui/material";
import PatientsList from "../PatientsList/PatientsList";

interface PatientsPageProps {

}

function PatientsPage(argProps: PatientsPageProps): React.ReactElement {
    const {...props} = argProps;
    return (
        <div {...props}>
            <Box m={1}>
                <PatientsList />
            </Box>
        </div>
    );
}

export default PatientsPage