import {buildRTKAPI} from "../builder";
import {baseApi} from "./base";
import {Condition, ConditionListParams} from "../../models/condition";


const api = baseApi.injectEndpoints({
    endpoints: (builder) => {
        const api = buildRTKAPI<
            Condition,
            ConditionListParams
        >(
            builder,
            "Condition",
            "/conditions",
            {
                // Options
            }
        );

        return {
            listConditions: api.getList,
        };
    },
});

export const {
    useListConditionsQuery,
} = api;
