import React from 'react';
import MuiAppBar, {AppBarProps as MuiAppBarProps} from '@mui/material/AppBar';
import {Button, Menu, MenuItem, Toolbar} from "@mui/material";
import HBox from "../../common/box/HBox/HBox";
import PopupState, {bindMenu, bindTrigger} from "material-ui-popup-state";
import {AccountCircle} from "@mui/icons-material";
import {useGetCurrentUserQuery, useLogoutAction} from "../../../store/api/session";
import OAuthTokenAppBarItem from "../OAuthTokenAppBarItem/OAuthTokenAppBarItem";
import AppBarNavigationButtons from "../AppBarNavigationButtons/AppBarNavigationButtons";
import {NavLink} from "react-router-dom";

interface AppBarProps extends MuiAppBarProps {

}



function AppBar(argProps: AppBarProps): React.ReactElement {
    const {...props} = argProps;

    const userState = useGetCurrentUserQuery();
    const [logout, {isLoading: isLoggingOut}] = useLogoutAction();
    async function onLogoutClick(){
        await logout();
    }

    const user = userState.data;

    return (
        <MuiAppBar variant={'outlined'} style={{
            background: '#fefaf6',
        }} {...props}>
            <HBox justifyContent={'space-between'}>
                <Toolbar>
                    <NavLink to={'/'}>
                        <img alt={'Home Page'} width={150} src={'/logo_with_title300.png'} />
                    </NavLink>
                    <AppBarNavigationButtons />
                </Toolbar>

                <Toolbar>
                    {!!user && <OAuthTokenAppBarItem user={user} />}
                    <PopupState
                        popupId={'user-menu'}
                        variant={'popover'}
                        disableAutoFocus={true}
                        parentPopupState={null}
                    >
                        {(popupState) => {
                            return <div>
                                <Button
                                    {...bindTrigger(popupState)}
                                >
                                    {user?.username || "User"}
                                    &nbsp;&nbsp;
                                    <AccountCircle/>
                                </Button>

                                <Menu {...bindMenu(popupState)}>

                                    <MenuItem disabled={isLoggingOut} onClick={onLogoutClick}>Log Out</MenuItem>
                                </Menu>
                            </div>

                        }}

                    </PopupState>
                </Toolbar>
            </HBox>

        </MuiAppBar>
    );
}

export default AppBar