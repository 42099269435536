import React from 'react'
import { createBrowserRouter, Outlet, RouterProvider } from 'react-router-dom'
import { LoginPage } from '../LoginPage/LoginPage'

export function UnauthorizedView(): React.ReactElement {
    return (
        <div>
            <Outlet />
        </div>
    )
}

const router = createBrowserRouter([
    {
        path: '',
        element: <UnauthorizedView />,
        children: [

            // {
            //     path: '/register',
            //     element: <div>Register</div>,
            // },

            {
                path: '*',
                element: <LoginPage />,
            },

            {
                index: true,
                element: <LoginPage />,
            },
        ],
    },
])

export function UnauthorizedRouter(): React.ReactElement {
    return <RouterProvider router={router} />
}
