import React from 'react';
import {Box, Button, Card, CardActions, CardContent, Divider, IconButton, Tooltip, Typography} from "@mui/material";
import {PCCFacility} from "../../../models/pccFacility";
import {useOnboardPCCFacilityMutation} from "../../../store/api/pccFacility";
import {handleErrorOrProceed} from "../../../common/formik";
import {toast} from "react-toastify";
import {useListFacilitiesQuery} from "../../../store/api/facility";
import StateLoadingWrapper from "../../common/StateLoadingWrapper/StateLoadingWrapper";
import {Refresh} from "@mui/icons-material";


interface PCCFacilityCardProps {
    facility: PCCFacility;
}

interface FacilityOnboardButtonProps {
    pccFacility: PCCFacility;
}

function FacilityOnboardButton(props: FacilityOnboardButtonProps): React.ReactElement {
    const {pccFacility} = props;
    const [onboardPCCFacility, {isLoading}] = useOnboardPCCFacilityMutation();

    const facState = useListFacilitiesQuery({pcc_id: pccFacility.facId})

    const facilities = facState.data?.results;
    const facility = facilities && facilities.length > 0 ? facilities[0] : undefined;

    async function onOnboardClicked(e: React.MouseEvent<HTMLButtonElement>) {
        e.preventDefault();
        e.stopPropagation();

        const res = await onboardPCCFacility(pccFacility);

        if (handleErrorOrProceed(res)) {
            toast.success("Initiated facility onboarding");
        }

    }


    return <StateLoadingWrapper state={facState}>

        {facilities && !facility && <Button
            variant={'contained'}
            color={'primary'}
            disabled={isLoading}
            onClick={onOnboardClicked}
        >
            Onboard
        </Button>}

        {facilities && facility && facility.isOnboarding && <><Button disabled={true}>
            {facility.remainingPatientsCount} left
        </Button>&nbsp;<Tooltip title={"Refresh status"}>
            <IconButton size={'small'} color={'primary'} disabled={facState.isFetching} onClick={() => {
                facState.refetch()
            }}><Refresh/></IconButton>
        </Tooltip>
        </>}

        {facilities && facility && !facility.isOnboarding && <Button
            variant={'contained'}
            color={'secondary'}
            disabled={isLoading}
            onClick={onOnboardClicked}

        >
            Re-onboard
        </Button>}

        {!facilities && <Button disabled={true}>
            Something went wrong
        </Button>}
    </StateLoadingWrapper>

}


function PCCFacilityCard(argProps: PCCFacilityCardProps): React.ReactElement {
    const {facility} = argProps;

    return (
        <Card>
            <Box m={1}>
                <CardContent>

                    <Typography>
                        {facility.facilityName}
                    </Typography>
                    <br/>
                    <Divider>
                        <Typography style={{fontSize: 8}}>ADDRESS</Typography>
                    </Divider>
                    <Typography variant={'caption'} component={'div'}>{facility.addressLine1}</Typography>
                    <Typography variant={'caption'} component={'div'}>{facility.addressLine2}</Typography>
                    <Typography variant={'caption'} component={'div'}>{facility.city}</Typography>
                    <Typography variant={'caption'} component={'div'}>{facility.country}</Typography>
                    <Divider>
                        <Typography style={{fontSize: 8}}>CONTACT</Typography>
                    </Divider>
                    <Typography variant={'caption'} component={'div'}>{facility.emailAddress || 'No email'}</Typography>
                    <Typography variant={'caption'} component={'div'}>{facility.phone}</Typography>
                </CardContent>
                <CardActions>
                    <FacilityOnboardButton pccFacility={facility}/>
                </CardActions>
            </Box>
        </Card>
    );
}

export default PCCFacilityCard