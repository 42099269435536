import React from 'react';
import PCCFacilitiesList from "../PCCFacilitiesList/PCCFacilitiesList";

interface FacilitiesPageProps {

}

function FacilitiesPage(argProps: FacilitiesPageProps): React.ReactElement {
    const {...props} = argProps;
    return (
        <div {...props}>
            <PCCFacilitiesList />
        </div>
    );
}

export default FacilitiesPage